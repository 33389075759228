export default {
  article: {
    banners: {
      disableAds: false,
    },
    settings: {
      adultContent: false,
      articleTemplate: 'default',
      contentMarketing: false,
      trending: false,
      customCss: null,
      includeSinglePurchase: true,
      issueOnly: false,
      helplineInformation: false,
      registeredUsersOnly: false,
      createdWithAi: false,
      specialProject: false,
    },
    comments: {
      disableComments: false,
      onlyRegisteredUsersAllowedToComment: false,
    },
    voiceSettings: {
      textToVoice: {
        voiceUrl: '',
      },
    },
    seoFields: {
      seoDescription: null,
      seoTitle: null,
      disallowIndexing: false,
    },
    contentValues: {
      newsLifetime: null,
      newsValue: null,
      topicCategory: null,
    },
    authors: {
      items: [],
    },
  },
  headlines: {
    settings: {
      contentMarketing: false,
      trending: false,
    },
    comments: {
      disableComments: false,
      onlyRegisteredUsersAllowedToComment: false,
    },
  },
};

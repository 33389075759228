import Vue from 'vue';
import { EmbeddedArticle } from '@root/modules/article/types/getArticleByID';
import buildPictureUrl from '@core/utils/buildPictureUrl';

interface Methods {
  getFocuspoint(focuspoint: { x: number | null; y: number | null } | null): Record<string, number>;
  getCustomCrop(customCrops: any[], ratio: string): Record<string, any> | undefined;
  getArticlePictureUrl(embeddedArticle: EmbeddedArticle, cropperData: Record<string, string>): string;
}

export default Vue.extend<unknown, Methods, unknown, unknown>({
  methods: {
    getFocuspoint(focuspoint) {
      const normalizedFocuspoint: { fx?: number; fy?: number } = {};

      if (Number.isFinite(focuspoint?.x)) {
        normalizedFocuspoint.fx = focuspoint!.x as number;
      }

      if (Number.isFinite(focuspoint?.y)) {
        normalizedFocuspoint.fy = focuspoint!.y as number;
      }

      return normalizedFocuspoint;
    },
    getCustomCrop(customCrops, ratio) {
      return customCrops.find((crop) => crop.ratio === ratio);
    },
    getArticlePictureUrl(embeddedArticle, cropperData) {
      const metaImage = embeddedArticle.metaImage;

      if (!metaImage) {
        return '';
      }

      const customCrop = this.getCustomCrop(metaImage.attributes?.cropperData?.customCrops || [], cropperData.r);
      const focuspoint = this.getFocuspoint(metaImage.attributes?.cropperData?.focuspoint);

      return buildPictureUrl({ id: metaImage.id, cropperData: { ...customCrop, ...focuspoint, ...cropperData } });
    },
  },
});

export default (buttonConfig: Record<string, string>): Record<string, string> | undefined => {
  // Only build custom theme when all colors are defined
  const isValidCustomTheme = !!buttonConfig.color && !!buttonConfig.hoverColor && !!buttonConfig.contrastColor;

  if (!isValidCustomTheme) {
    return undefined;
  }

  const result = {
    background: buttonConfig.color,
    backgroundHover: buttonConfig.hoverColor,
    text: buttonConfig.contrastColor,
  };

  return result;
};

import { Plugin } from '@nuxt/types';

const cXensePixelTrackerPlugin: Plugin = async ({ store }) => {
  const generateRandomString = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  };

  const trackAdEvent = (customParams: Record<string, string>): void => {
    const url = new URL('https://comcluster.cxense.com/Repo/rep.gif');
    const params = {
      ver: '1',
      typ: 'pgv',
      sid: '1354876583684532490',
      ckp: `usr_${generateRandomString(15)}`,
      rnd: Math.random() * (5.5 - 1.5) + 1.5,
      loc: document.location.href,
    };

    for (const [key, value] of Object.entries(customParams)) {
      url.searchParams.set(`cp_${key}`, value.toString());
    }

    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value.toString());
    }

    const img = document.createElement('img');
    img.src = url.toString();
    img.style.display = 'none';
    document.body.appendChild(img);
  };

  store.watch(
    (state) => state.analytics.arrayTracker.cXensePixel,
    async () => {
      if (!store.state.analytics.arrayTracker.cXensePixel.length) {
        return;
      }

      const firstEvent = store.state.analytics.arrayTracker.cXensePixel.shift();

      trackAdEvent(firstEvent.eventData.data);
    }
  );
};

export default cXensePixelTrackerPlugin;


import Vue from 'vue';
import { Vendor } from '@iabtechlabtcf/core';

interface Methods {
  getVendorDomains(): string[];
  getUrlsOrigin(...urls: string[]): string[];
}

interface Props {
  vendor: Vendor;
  showDetails: boolean;
  type: string;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  props: {
    vendor: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      default: 'tcf',
    },
  },
  methods: {
    getVendorDomains() {
      const domains: string[] = [];

      if (!this.vendor.urls) {
        return [];
      }

      for (const url of this.vendor.urls) {
        const origins: string[] = this.getUrlsOrigin(url.privacy, url.legIntClaim);

        for (const origin of origins) {
          if (!domains.includes(origin)) {
            domains.push(origin);
          }
        }
      }

      return domains;
    },
    getUrlsOrigin(...urls: string[]) {
      const origins: string[] = [];

      for (const url of urls) {
        try {
          origins.push(new URL(url).origin);
        } catch {
          /* empty */
        }
      }

      return origins;
    },
  },
});


import Vue from 'vue';
import HeaderCustomerMenu from '@root/common/components/layout/header/HeaderCustomerMenu.vue';
import Dropdown from '@core/components/UI/dropdown/Dropdown.vue';
import { ExtendedProfile, PianoCustomerProfile } from '@piano/types/pianoState';

interface Data {
  forceClose: {
    value: boolean;
    timestamp: string;
  };
}

interface Props {
  data: {
    isLoggedIn: boolean;
    profile: PianoCustomerProfile | null;
    extendedProfile: ExtendedProfile | null;
  };
  openLoginModal: () => void;
  logout: () => void;
}

interface Methods {
  linkClicked: () => void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    HeaderCustomerMenu,
    Dropdown,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    openLoginModal: {
      type: Function,
      required: true,
    },
    logout: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      forceClose: {
        value: false,
        timestamp: '',
      },
    };
  },
  methods: {
    linkClicked() {
      this.forceClose = {
        value: true,
        timestamp: String(new Date().getTime()),
      };
    },
  },
});

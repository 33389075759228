
import Vue from 'vue';
import { AudioItem } from '@media/types/getAudio';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';

interface Data {
  appleStoreLink: string;
  googlePlayStoreLink: string;
}

interface Methods {
  handleTrackChange: (episodeData: AudioItem) => void;
}

interface Props {
  activeEpisodeId: string;
  playlist: AudioItem[];
  podcastId: string | null;
}

interface Computed {
  locale: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    LinkHandler,
  },
  props: {
    playlist: {
      type: Array,
      required: true,
    },
    activeEpisodeId: {
      type: String,
      required: false,
    },
    podcastId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      appleStoreLink: 'https://apps.apple.com/ee/app/delfi-tasku/id6443734750',
      googlePlayStoreLink: 'https://play.google.com/store/apps/details?id=ee.delfi.tasku',
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    handleTrackChange(episodeData) {
      this.$emit('handle-track-change', episodeData.id);
    },
  },
});

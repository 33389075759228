import resetUserQuery from '@piano/apollo/graphql/resetUser.graphql';
import deleteUserQuery from '@piano/apollo/graphql/deleteUser.graphql';
import saveCustomFieldQuery from '@piano/apollo/graphql/saveCustomField.graphql';
import { handleApolloClientMutation } from '@piano/apollo/apolloClient';
import { ResetUser } from '@piano/apollo/types/ResetUser';
import { DeleteUser } from '@piano/apollo/types/DeleteUser';
import { SaveCustomField } from '@piano/apollo/types/SaveCustomField';
import { MutationOptions } from '@apollo/client/core';
import { PianoConfig, PianoState } from '@piano/types/pianoState';

export default class PianoWorkerService {
  private readonly _apiUrl: string;
  private _token: PianoState['token'] = null;

  constructor(apiUrl: PianoConfig['pianoWorkerApiUrl']) {
    this._apiUrl = apiUrl || '';
  }

  public setToken(token: PianoState['token']): void {
    this._token = token;
  }

  public isServiceReady(): boolean {
    return Boolean(this._token && this._apiUrl);
  }

  public async resetUser(): Promise<boolean> {
    const options = {
      mutation: resetUserQuery,
      context: {
        headers: {
          Authorization: `Bearer ${this._token}`,
        },
      },
    };

    try {
      const response = await handleApolloClientMutation<ResetUser['data']>(options, this._apiUrl);

      return response.data?.resetUser.status || false;
    } catch (e) {
      console.log('PianoWorkerService error', e);
      return false;
    }
  }

  public async deleteUser(): Promise<[boolean, Error | null]> {
    const options = {
      mutation: deleteUserQuery,
      context: {
        headers: {
          Authorization: `Bearer ${this._token}`,
        },
      },
    };

    try {
      const response = await handleApolloClientMutation<DeleteUser['data']>(options, this._apiUrl);
      return [response.data?.deleteUser.status || false, null];
    } catch (e) {
      console.log('PianoWorkerService error', e);
      return [false, e];
    }
  }

  public async saveCustomField(variables: SaveCustomField['variables']): Promise<boolean> {
    const options: MutationOptions<SaveCustomField['data'], SaveCustomField['variables']> = {
      mutation: saveCustomFieldQuery,
      context: {
        headers: {
          Authorization: `Bearer ${this._token}`,
        },
      },
      variables,
    };

    try {
      const response = await handleApolloClientMutation<SaveCustomField['data'], SaveCustomField['variables']>(options, this._apiUrl);

      return response.data?.saveCustomField.status || false;
    } catch (e) {
      console.log('PianoWorkerService error', e);
      return false;
    }
  }
}

export const getGlobalContext = (context: {
  channelAccess?: boolean;
  isLoggedIn?: boolean;
  channelId?: string;
  isNativeApp?: boolean;
}): { key: string; value: string }[] | undefined => {
  const { channelAccess = false, isLoggedIn = false, channelId = '', isNativeApp = false } = context;

  return [
    { key: 'userType', value: channelAccess ? 'tellija' : 'm-tellija' },
    { key: 'channel', value: `channel_${channelId}` },
    { key: 'isLoggedIn', value: isLoggedIn ? 'yes' : 'no' },
    { key: 'isNativeApp', value: isNativeApp ? 'yes' : 'no' },
  ];
};

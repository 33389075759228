import { Plugin } from '@nuxt/types';

type AbGroup = 'A' | 'B';

declare module 'vue/types/vue' {
  interface Vue {
    $abTest?: {
      getGroup: () => AbGroup | undefined;
    };
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $abTest?: {
      getGroup: () => AbGroup | undefined;
    };
  }
  interface Context {
    $abTest?: {
      getGroup: () => AbGroup | undefined;
    };
  }
}

const chooseABTestGroup = (splitType: '50-50' | '20-20-60'): AbGroup | undefined => {
  const random = Math.random();
  if (splitType === '50-50') {
    return random < 0.5 ? 'A' : 'B';
  } else {
    if (random < 0.2) {
      return 'A';
    } else if (random < 0.4) {
      return 'B';
    }
  }
};

const abTestPlugin: Plugin = async ({ app }, inject) => {
  const cookieName = 'ab_test_group';
  const cookieOptions = {
    path: '/',
    maxAge: 60 * 60 * 24 * 7, // 7 days in seconds
    sameSite: 'lax',
  };

  const $cookies = app.$cookies;
  let group = $cookies.get(cookieName);

  if (!group) {
    group = chooseABTestGroup('50-50');
    $cookies.set(cookieName, group, cookieOptions);
  }

  inject('abTest', {
    getGroup: () => group,
  });
};

export default abTestPlugin;

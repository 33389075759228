export default {
  PAYWALL_FLOW: {
    types: {
      paywall_shown: {
        eventType: 'paywall_shown',
        customParams: '{"ShownOfferId": "${data.offerId}", "AbTestID": "${data.abTestID}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      paywall_cta_click: {
        eventType: 'paywall_cta_click',
        customParams: '{"OfferId": "${data.offerId}", "TermId": "${data.termId}", "AbTestID": "${data.abTestID}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      paywall_already_purchased: {
        eventType: 'paywall_already_purchased',
        customParams: '{"AbTestID": "${data.abTestID}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      paywall_promo_applied: {
        eventType: 'paywall_promo_applied',
        customParams: '{"OfferId": "${data.offerId}", "TermId": "${data.termId}", "PromotionId": "${data.promotionId}", "AbTestID": "${data.abTestID}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      paywall_login_success: {
        eventType: 'paywall_login_success',
        customParams: '{"AbTestID": "${data.abTestID}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      paywall_register_success: {
        eventType: 'paywall_register_success',
        customParams: '{"AbTestID": "${data.abTestID}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      stripe_elements: {
        eventType: 'paywall_method_stripe_elements',
        customParams: '{"AbTestID": "${data.abTestID}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      zlick: {
        eventType: 'paywall_method_zlick',
        customParams: '{"AbTestID": "${data.abTestID}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      paywall_purchase_start: {
        eventType: 'paywall_purchase_start',
        customParams: '{"ChargeAmount": "${data.chargeAmount}", "TermId": "${data.termId}", "AbTestID": "${data.abTestID}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      paywall_purchase_complete: {
        eventType: 'paywall_purchase_complete',
        customParams: '{"ChargeAmount": "${data.chargeAmount}", "TermId": "${data.termId}", "AbTestID": "${data.abTestID}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
    },
  },
  CHURN_PREVENTION: {
    types: {
      churn_flow_started: {
        eventType: 'churn_flow_started',
        customParams:
          '{"CurrentStepNumber": "${data.currentStepNumber}", "CurrentStepVariation": "${data.currentStepVariation}", "DaysSubscribed": "${data.daysSubscribed}", "UsedSharedAccountsCount": "${data.usedSharedAccountsCount}", "CurrentTermName": "${data.currentTermName}", "CurrentTermPrice": "${data.currentTermPrice}", "OfferTermName": "${data.offerTermName}", "OfferTermPrice": "${data.offerTermPrice}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      churn_modal_shown: {
        eventType: 'churn_modal_shown',
        customParams:
          '{"CurrentStepNumber": "${data.currentStepNumber}", "CurrentStepVariation": "${data.currentStepVariation}", "DaysSubscribed": "${data.daysSubscribed}", "UsedSharedAccountsCount": "${data.usedSharedAccountsCount}", "CurrentTermName": "${data.currentTermName}", "CurrentTermPrice": "${data.currentTermPrice}", "OfferTermName": "${data.offerTermName}", "OfferTermPrice": "${data.offerTermPrice}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      churn_flow_progress: {
        eventType: 'churn_flow_progress',
        customParams:
          '{"CurrentStepNumber": "${data.currentStepNumber}", "CurrentStepVariation": "${data.currentStepVariation}", "DaysSubscribed": "${data.daysSubscribed}", "UsedSharedAccountsCount": "${data.usedSharedAccountsCount}", "CurrentTermName": "${data.currentTermName}", "CurrentTermPrice": "${data.currentTermPrice}", "OfferTermName": "${data.offerTermName}", "OfferTermPrice": "${data.offerTermPrice}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      set_renew_off: {
        eventType: 'set_renew_off',
        customParams:
          '{"CurrentStepNumber": "${data.currentStepNumber}", "CurrentStepVariation": "${data.currentStepVariation}", "DaysSubscribed": "${data.daysSubscribed}", "UsedSharedAccountsCount": "${data.usedSharedAccountsCount}", "CurrentTermName": "${data.currentTermName}", "CurrentTermPrice": "${data.currentTermPrice}", "OfferTermName": "${data.offerTermName}", "OfferTermPrice": "${data.offerTermPrice}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      set_renew_off_wo_offer: {
        eventType: 'set_renew_off_wo_offer',
        customParams:
          '{"CurrentStepNumber": "${data.currentStepNumber}", "CurrentStepVariation": "${data.currentStepVariation}", "DaysSubscribed": "${data.daysSubscribed}", "UsedSharedAccountsCount": "${data.usedSharedAccountsCount}", "CurrentTermName": "${data.currentTermName}", "CurrentTermPrice": "${data.currentTermPrice}", "OfferTermName": "${data.offerTermName}", "OfferTermPrice": "${data.offerTermPrice}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      set_renew_on: {
        eventType: 'set_renew_on',
        customParams:
          '{"CurrentStepNumber": "${data.currentStepNumber}", "CurrentStepVariation": "${data.currentStepVariation}", "DaysSubscribed": "${data.daysSubscribed}", "UsedSharedAccountsCount": "${data.usedSharedAccountsCount}", "CurrentTermName": "${data.currentTermName}", "CurrentTermPrice": "${data.currentTermPrice}", "OfferTermName": "${data.offerTermName}", "OfferTermPrice": "${data.offerTermPrice}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      accept_offer: {
        eventType: 'accept_offer',
        customParams:
          '{"CurrentStepNumber": "${data.currentStepNumber}", "CurrentStepVariation": "${data.currentStepVariation}", "DaysSubscribed": "${data.daysSubscribed}", "UsedSharedAccountsCount": "${data.usedSharedAccountsCount}", "CurrentTermName": "${data.currentTermName}", "CurrentTermPrice": "${data.currentTermPrice}", "OfferTermName": "${data.offerTermName}", "OfferTermPrice": "${data.offerTermPrice}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      open_adfree_checkout: {
        eventType: 'open_adfree_checkout',
        customParams:
          '{"CurrentStepNumber": "${data.currentStepNumber}", "CurrentStepVariation": "${data.currentStepVariation}", "DaysSubscribed": "${data.daysSubscribed}", "UsedSharedAccountsCount": "${data.usedSharedAccountsCount}", "CurrentTermName": "${data.currentTermName}", "CurrentTermPrice": "${data.currentTermPrice}", "OfferTermName": "${data.offerTermName}", "OfferTermPrice": "${data.offerTermPrice}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      churn_flow_closed: {
        eventType: 'churn_flow_closed',
        customParams:
          '{"CurrentStepNumber": "${data.currentStepNumber}", "CurrentStepVariation": "${data.currentStepVariation}", "DaysSubscribed": "${data.daysSubscribed}", "UsedSharedAccountsCount": "${data.usedSharedAccountsCount}", "CurrentTermName": "${data.currentTermName}", "CurrentTermPrice": "${data.currentTermPrice}", "OfferTermName": "${data.offerTermName}", "OfferTermPrice": "${data.offerTermPrice}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      error_alert: {
        eventType: 'error_alert',
        customParams: '{"ErrorDetails": "${data.errorDetails}", "CurrentTermName": "${data.currentTermName}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
    },
  },
  SESSION_LIMIT: {
    types: {
      show_alert: {
        eventType: 'show_alert',
        customParams: '{"UserAgents": "${data.userAgents}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      reset_password: {
        eventType: 'reset_password',
        customParams: '',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
    },
  },
  ONBOARDING_MODAL: {
    types: {
      onboarding_shown: {
        eventType: 'onboarding_shown',
        customParams: '',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      onboarding_progress: {
        eventType: 'onboarding_progress',
        customParams: '{"Progress": "${data.progress}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      onboarding_closed: {
        eventType: 'onboarding_closed',
        customParams: '',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      onboarding_newsletter: {
        eventType: 'onboarding_newsletter',
        customParams: '{"ConsentCode": "${data.consentCode}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      onboarding_topic: {
        eventType: 'onboarding_topic',
        customParams: '{"Slug": "${data.slug}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      onboarding_repeat_shown: {
        eventType: 'onboarding_repeat_shown',
        customParams: '',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      onboarding_repeat_progress: {
        eventType: 'onboarding_repeat_progress',
        customParams: '{"Progress": "${data.progress}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      onboarding_repeat_closed: {
        eventType: 'onboarding_repeat_closed',
        customParams: '',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      onboarding_repeat_newsletter: {
        eventType: 'onboarding_repeat_newsletter',
        customParams: '{"ConsentCode": "${data.consentCode}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      onboarding_repeat_topic: {
        eventType: 'onboarding_repeat_topic',
        customParams: '{"Slug": "${data.slug}"}',
        options: '{"origin":"eks-funnel", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
    },
  },
};

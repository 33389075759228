var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content-embedded-articles-list"},[_vm._l((_vm.embeddedArticles.items),function(embeddedArticle,index){return [_c('LinkHandler',{key:index,staticClass:"content-embedded-articles-list__embedded-article",attrs:{"href":_vm.buildArticleRoute(
          embeddedArticle.id,
          embeddedArticle.slug,
          {
            language: embeddedArticle.categories.items[0].channel.language,
            domain: embeddedArticle.categories.items[0].channel.url,
          },
          _vm.domain
        )}},[_c('div',{staticClass:"content-embedded-articles-list__info"},[_c('div',{staticClass:"content-embedded-articles-list__published-at"},[_vm._v("\n          "+_vm._s(_vm.formatCustomDate(embeddedArticle.publishAt, 'DD.MM.YYYY'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"content-embedded-articles-list__title"},[_c('span',{staticClass:"content-embedded-articles-list__title-text"},[_vm._v("\n            "+_vm._s(embeddedArticle.content.title.text)+"\n          ")]),_vm._v(" "),(embeddedArticle.statistics.comments)?_c('span',{staticClass:"content-embedded-articles-list__comments-count"},[_vm._v("\n            "+_vm._s(`(${embeddedArticle.statistics.comments})`)+"\n          ")]):_vm._e()])])])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { Plugin } from '@nuxt/types';
import isPianoIframeOpen from '@root/modules/piano/utils/isPianoIframeOpen';
import { resolvePromises } from '@root/common/utils/resolvePromises';
import { getEnv } from '@root/common/utils/getEnv';
import { CheckoutLink } from '@root/modules/piano/utils/CheckoutLink';
import PianoWorkerService from '@piano/services/PianoWorker.service';
import { PianoState } from '@piano/types/pianoState';

const pianoOrigin = ['https://auth.piano.delfi.ee', 'https://vx.piano.delfi.ee', 'https://sandbox.piano.io'];

/*
  https://docs.piano.io/getting-started-module-2-setting-up-piano-scripts/#thepianoscript
  https://docs.piano.io/track/id-implementation
  https://docs.piano.io/callbacks/
  */

interface window extends Window {
  abTestId?: string;
  openOnboardingModal: () => void;
}

declare let window: window;
const piano: Plugin = async (ctx) => {
  const { $sentry, app, store, route } = ctx;
  const { locale = 'et_EE', domain = '' } = app.$channelConfig('settings');
  const { piano } = app.$channelConfig('features');
  const baseUrl = app.$channelConfig('meta').base.baseUrl;

  const disableMaintenance = Number(route.query?.disableMaintenance) === 1 || false;
  const maintenanceMode = piano.maintenanceMode || false;

  window.openOnboardingModal = () => {
    store.dispatch('piano/toggleModal', { name: 'onboardingModal', show: true });
  };

  let scriptFailedCount = 0;

  if (!app.mixins) {
    app.mixins = [];
  }

  const initPiano = async () => {
    await store.dispatch('piano/init', {
      channel: baseUrl,
      aid: piano.account.aid,
      scriptSrc: piano.account.scriptSrc,
      locale,
      accessResources: piano.accessResources,
      offersConfiguration: piano.offersConfiguration,
      ESPId: piano.account.ESPId,
      maintenanceMode: maintenanceMode && !disableMaintenance,
      defaultPromotions: piano.defaultPromotions,
      isApplication: Boolean(app.$channelConfig('settings').application.type) && piano.enableNativeLogin,
      pianoWorkerApiUrl: getEnv('pianoWorkerApiUrl'),
      ipAccessApiUrl: getEnv('ipAccessApiUrl'),
    });

    const checkoutLink = new CheckoutLink(ctx.route.query);
    const checkoutParams = checkoutLink.getCheckoutOpenParamsIfValid();

    if (checkoutParams) {
      store.commit('analytics/setClickEvent', {
        cXense: {
          eventName: 'ABANDONED_CART_CHECKOUT',
          eventData: { type: 'link' },
        },
      });

      const queryWithoutCheckoutFields = checkoutLink.getQueryWithoutCheckoutFields();
      ctx.app.router?.replace({ query: queryWithoutCheckoutFields });

      await store.dispatch('piano/addTags', ['abandoned-cart-link-checkout']);
      await store.dispatch(`piano/${checkoutParams.type}`, checkoutParams.params);
    }
  };

  /*
      Piano SDK is initialized in the client-side plugin (when hydration starts)
      On checkout, Piano applies get parameters to the URL and reloads the page
      After reloading Piano SDK is initialized again, and it removes these parameters
      But because it happens **during** hydration, they are applied again by Vue-Router or Nuxt at the end of hydration
      So we need to remove them manually after hydration ends and after Piano SDK is initialized
   */
  app.mixins.push({
    async mounted() {
      await initPiano();
    },
  });

  /*
   * Whenever new content is loaded without a browser refresh, you’ll need to execute the
   * Piano experience you've designed in Composer so that each new applicable change on the
   * page or URL is made known to Composer in place of a true page load.
   * */
  app.router!.afterEach((to, from) => {
    if (from.name) {
      const routeName = to.name;

      store.dispatch('piano/setTags', [domain, routeName]);
      store.dispatch('piano/executeExperience');
    }
  });

  const isScriptFailedWatcher = store.watch(
    (state) => state.piano.scriptFailed,
    async (scriptFailed) => {
      if (scriptFailed) {
        scriptFailedCount++;
        const activeArticleId = store.state.article.activeArticle?.id || null;

        store.commit('analytics/setClickEvent', {
          cXense: {
            eventName: 'PIANO_LOAD_FAILURE',
            eventData: {
              type: 'view',
              data: {
                activeArticleId,
                scriptFailedCount,
              },
            },
          },
        });
        $sentry.captureException(`Piano initialization failed after ${scriptFailedCount} attempts`, {
          tags: { 'process.type': 'client' },
        });
        store.commit('piano/setScriptLoaded', false);

        if (scriptFailedCount > 2) {
          store.commit('setPianoInitializationError', true);

          // Needed to avoid "not a function" error when fired immediately
          await resolvePromises();
          isScriptFailedWatcher();
        } else {
          await initPiano();
        }
      }
    },
    {
      immediate: true,
    }
  );

  store.watch(
    (state) => state.piano.scriptLoaded,
    async (scriptLoaded) => {
      if (scriptLoaded) {
        /*
         * Should be fired only once, do some actions that are needed on first load
         * */

        const routeName = app.router?.currentRoute.name;
        await store.dispatch('piano/setTags', [domain, routeName]);
      }
    },
    {
      immediate: true,
    }
  );

  store.watch(
    (state) => state.piano.isLoggedIn,
    async (isLoggedIn) => {
      if (!isLoggedIn) {
        return;
      }

      const { isAbandonedCartActive, abandonedCartData } = store.state.piano.extendedProfile;
      const activeConversions: PianoState['activeConversions'] = store.state.piano.activeConversions;

      if (!isAbandonedCartActive || !abandonedCartData || activeConversions.length > 0) {
        return;
      }

      let searchParams: URLSearchParams | undefined = undefined;

      try {
        searchParams = new URLSearchParams(abandonedCartData);
      } catch (e) {
        console.log('Failed to parse abandoned cart data to searchParams', e);
      }

      if (!searchParams) {
        return;
      }

      const searchParamsObject = Object.fromEntries(searchParams.entries());
      const checkoutLink = new CheckoutLink(searchParamsObject);
      const checkoutParams = checkoutLink.getCheckoutOpenParamsIfValid();

      if (!checkoutParams) {
        return;
      }

      app.$alertHandler.addAlert({
        type: 'info',
        title: 'alerts.piano_abandoned_cart.title',
        text: 'alerts.piano_abandoned_cart.text',
        customIcon: {
          src: 'https://static.delfi.ee/icons/bell.svg',
        },
        buttons: [
          {
            text: 'alerts.piano_abandoned_cart.buttons.continue_order',
            customTheme: {
              background: '#00d46e',
              backgroundHover: '#00bb61',
              text: '#fff',
              iconColor: '#fff',
            },
            icon: 'total-package',
            iconPosition: 'left',
            action: async () => {
              await store.dispatch('piano/addTags', ['abandoned-cart-alert-checkout']);
              await store.dispatch(`piano/${checkoutParams.type}`, checkoutParams.params);
            },
          },
        ],
        closeCallback: async () => {
          const pianoWorkerService = new PianoWorkerService(getEnv('pianoWorkerApiUrl'));
          pianoWorkerService.setToken(store.state.piano.token);

          if (pianoWorkerService.isServiceReady()) {
            await pianoWorkerService.saveCustomField({ input: [{ key: 'isAbandonedCartActive', value: 'false' }] });
          }
        },
        options: {
          key: 'piano-abandoned-cart',
          showAgainAfterNMinutes: 60,
        },
      });
    },
    {
      immediate: true,
    }
  );

  store.watch(
    (state) => state.piano.isNewCustomer,
    async (isNewCustomer) => {
      if (isNewCustomer) {
        app.$alertHandler.addAlert({
          type: 'success',
          title: 'signup.success_alert.title',
          text: 'signup.success_alert.text',
          fadeTrigger: 7000,
          options: {
            key: 'signup-success-alert',
          },
        });
      }
    }
  );

  store.watch(
    (state) => state.piano.access,
    async (access) => {
      app.$eventHandler.emit('piano-paywall-state', access);
    }
  );

  // Listen for Piano postMessages
  // deepcode ignore InsufficientPostmessageValidation: <origin is check, snyk does not understand it>
  window.addEventListener('message', ({ data, origin }: { data: string; origin: string }) => {
    if (!pianoOrigin.includes(origin)) {
      return;
    }

    let parsedParams: any = undefined;

    try {
      parsedParams = JSON.parse(data);
    } catch {
      return;
    }

    // Dispatched when checkout completed modal is opened
    if (parsedParams?.event === 'checkoutStateChange' && parsedParams.params?.stateName === 'receipt') {
      store.commit('analytics/setClickEvent', {
        cXense: {
          eventName: 'PAYWALL_MODAL',
          eventData: { type: 'view' },
        },
        googleTagManager: {
          eventName: 'NEW_SUBSCRIPTION',
          eventData: { eventType: 'click', data: { order_customer_id: store.state.piano.profile.uid } },
        },
      });

      store.commit('analytics/pushTrackingEvent', {
        cXenseFunnel: {
          eventName: 'PAYWALL_FLOW',
          eventData: {
            type: 'paywall_purchase_complete',
            data: { termId: parsedParams.params?.termId, chargeAmount: parsedParams.params?.term?.chargeAmount, abTestID: window.abTestId || '' },
          },
        },
      });
    }

    if (parsedParams?.event === 'checkoutStateChange' && parsedParams.params?.stateName === 'offer' && !isPianoIframeOpen()) {
      store.commit('analytics/pushTrackingEvent', {
        cXenseFunnel: {
          eventName: 'PAYWALL_FLOW',
          eventData: { type: 'paywall_shown', data: { offerId: parsedParams.params.offerId, abTestID: window.abTestId || '' } },
        },
      });
    }

    if (parsedParams?.event === 'alreadyPurchased') {
      store.commit('analytics/pushTrackingEvent', {
        cXenseFunnel: {
          eventName: 'PAYWALL_FLOW',
          eventData: { type: 'paywall_already_purchased', data: { abTestID: window.abTestId || '' } },
        },
      });
    }

    if (parsedParams?.event === 'loginSuccess' && parsedParams?.sender.includes('offer')) {
      store.commit('analytics/pushTrackingEvent', {
        cXenseFunnel: {
          eventName: 'PAYWALL_FLOW',
          eventData: {
            type: parsedParams.params.registration ? 'paywall_register_success' : 'paywall_login_success',
            data: { abTestID: window.abTestId || '' },
          },
        },
      });
    }

    // Custom view events
    if (parsedParams?.event === 'customTrackingEvent') {
      store.commit('analytics/pushTrackingEvent', {
        cXenseFunnel: {
          eventName: parsedParams.params.eventName,
          eventData: parsedParams.params.eventData,
        },
      });
    }

    // Custom click events
    // Example external-event="payment-method-click" external-event-type="funnel" external-event-params="{{ { eventName: 'PAYWALL_FLOW', eventData: {type: pm.identifier } } }}"
    if (parsedParams?.event === 'customEvent') {
      if (parsedParams?.params.eventName === 'cancel-subscription-click') {
        store.dispatch('piano/toggleModal', { name: 'churnPreventionModal', show: true });
      }

      if (parsedParams?.params.eventName === 'delete-account-click') {
        store.dispatch('piano/toggleModal', { name: 'accountDeletionModal', show: true });
      }

      const payload = parsedParams.params.params;
      if (payload.type === 'funnel') {
        try {
          const parsedEvent = JSON.parse(payload.params);
          store.commit('piano/setSubscriptionId', {
            subscriptionId: parsedEvent.eventData.type,
          });
          store.commit('analytics/pushTrackingEvent', {
            cXenseFunnel: parsedEvent,
          });
        } catch (e) {
          console.log('Failed to parse funnel event data');
        }
      }
    }

    if (parsedParams?.event === 'submitPayment') {
      store.commit('analytics/pushTrackingEvent', {
        cXenseFunnel: {
          eventName: 'PAYWALL_FLOW',
          eventData: {
            type: 'paywall_purchase_start',
            data: { termId: parsedParams.params?.termId, chargeAmount: parsedParams.params?.term?.chargeAmount, abTestID: window.abTestId || '' },
          },
        },
      });
    }

    if (parsedParams?.event === 'emitGAEvent' && parsedParams.params?.eventCategory === 'promoApplied') {
      store.commit('analytics/pushTrackingEvent', {
        cXenseFunnel: {
          eventName: 'PAYWALL_FLOW',
          eventData: {
            type: 'paywall_promo_applied',
            data: {
              termId: parsedParams.params?.termId,
              offerId: parsedParams.params?.offerId,
              promotionId: parsedParams.params?.promotionId,
              abTestID: window.abTestId || '',
            },
          },
        },
      });
    }

    if (parsedParams?.event === 'checkoutStateChange' && parsedParams.params?.stateName === 'state2') {
      store.commit('analytics/pushTrackingEvent', {
        cXenseFunnel: {
          eventName: 'PAYWALL_FLOW',
          eventData: {
            type: 'paywall_cta_click',
            data: { termId: parsedParams.params?.termId, offerId: parsedParams.params?.offerId, abTestID: window.abTestId || '' },
          },
        },
      });
    }
  });
};

export default piano;


import Vue from 'vue';
import Player from '@media/components/base/Player.vue';
import Timer from '@media/components/timer/Timer.vue';
import config from '@media/config/media.config';
import { AdsData, getPlayerAdSchedule } from '@media/utils/getPlayerAdSchedule';
import { getVideo } from '@media/services/getVideo';
import { VideoItem } from '@media/types/getVideo';
import { PortalPlayer } from '@media/services/player';
import { isDateBetween, isDateBefore } from '@core/filters/dateFormater';
import adSettings from '@media/config/videoPlayerSettings.config';
import { AdScheduleSettings } from '@media/types/PlayerAdSchedule';

interface Computed {
  hasChannelAccess: boolean;
  videoId: VideoItem['id'];
  isStream: boolean;
  isLive: boolean;
  hasLiveEnd: boolean;
  isLiveStartDateInFuture: boolean;
  streamTimerStartDate: Date | null;
}

interface Props {
  data: {
    externalSource?: boolean;
    attrs: VideoItem;
  };
  type: string;
  ads: AdsData;
  shouldPlayerFloat: boolean;
}

interface Methods {
  setupPlayerWithData(): Promise<void>;
  initPlayer: () => Promise<void>;
  getPlayerAds: () => AdScheduleSettings | null;
  getVideoPoster: (attrs: VideoItem, videoData: VideoItem | null) => string;
  getSubtitleTracks: (videoData: VideoItem | null) => VideoSubtitleTrackData[] | null;
  getSettings: () => Record<string, unknown>;
  scrollEventCallback: () => void;
  onStreamStart: () => void;
}

interface Data {
  showOnlyPoster: boolean;
  videoPoster: string | null;
  autostart: boolean;
  containerId: string;
  videoData: VideoItem | null;
  player: PortalPlayer | null;
}

interface VideoSubtitleTrackData {
  default: boolean;
  file: string;
  label: string;
}

let index = 0;

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Player,
    Timer,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    ads: {
      type: Object as () => AdsData,
      required: false,
      default: () => ({
        adFree: false,
        keywords: [],
        ppid: '',
      }),
    },
    shouldPlayerFloat: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    index++;

    return {
      showOnlyPoster: false,
      videoPoster: null,
      containerId: `media-${this.type}-${this.data.attrs.id}`,
      videoData: null,
      autostart: index === 1,
      player: null,
    };
  },
  computed: {
    hasChannelAccess() {
      return this.$store.state.piano.access.channelAccess;
    },
    videoId() {
      return this.data.attrs.id;
    },
    isLive() {
      const data = this.videoData?.metadata;
      const streamStartTime = data?.streamStartTime;
      const streamEndTime = data?.streamEndTime;

      const scheduleSet = Boolean(streamStartTime && streamEndTime);
      return scheduleSet ? isDateBetween(new Date().toString(), streamStartTime!, streamEndTime!) : true;
    },
    isStream() {
      return this.videoData?.type === 'STREAM';
    },
    hasLiveEnd() {
      const streamEndTime = this.videoData?.metadata.streamEndTime;

      if (!streamEndTime) {
        return false;
      }

      return isDateBefore(streamEndTime, new Date().toString());
    },
    isLiveStartDateInFuture() {
      const streamStartTime = this.videoData?.metadata.streamStartTime;

      if (!streamStartTime) {
        return false;
      }
      return isDateBefore(new Date().toString(), streamStartTime);
    },
    streamTimerStartDate() {
      const startTime = this.videoData?.metadata.streamStartTime;
      return startTime ? new Date(startTime) : null;
    },
  },
  watch: {
    async videoId() {
      if (!this.$isServer) {
        await this.setupPlayerWithData();
      }
    },
    hasChannelAccess(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setupPlayerWithData();
      }
    },
  },
  methods: {
    onStreamStart() {
      this.showOnlyPoster = false;
      setTimeout(() => {
        this.setupPlayerWithData();
      }, 1000);
    },
    scrollEventCallback() {
      if (!this.shouldPlayerFloat) {
        return;
      }

      this.player?.decideFloatingModeToggle();
    },
    getPlayerAds() {
      const { attrs } = this.data;

      const metadata = this.videoData?.metadata || attrs.metadata;
      const streamChannel = metadata?.streamChannel || '';

      const ads = Object.assign({ keywords: [] }, this.ads);
      ads.keywords.push(streamChannel);
      ads.ppid = this.$store.getters['adForm/getPPID'];

      return getPlayerAdSchedule(this.type, ads, this.hasChannelAccess, this.videoData?.videoData?.videoDuration);
    },
    getVideoPoster(attrs, videoData) {
      const videoPreviewImage = attrs?.metadata?.videoPreviewImage || videoData?.metadata.videoPreviewImage || {};
      if ('src' in videoPreviewImage && videoPreviewImage?.src) {
        return videoPreviewImage.src;
      } else if ('id' in videoPreviewImage && videoPreviewImage?.id) {
        return `${config.api.mediaApiUrl}/${videoPreviewImage.id}.jpg?noup&r=16:9&w=960`;
      }
      return '';
    },
    getSubtitleTracks(videoData) {
      return (
        videoData?.subtitles?.map((track, index) => {
          return {
            default: index === 0,
            file: track.videoSubtitlesURL,
            label: track.language,
          };
        }) || []
      );
    },
    getSettings() {
      const { attrs } = this.data;
      this.videoPoster = this.getVideoPoster(attrs, this.videoData);
      const tracks = this.getSubtitleTracks(this.videoData) ?? undefined;
      const autostart = this.data.attrs.autostart ?? true;
      const advertising = this.videoData?.metadata.adsDisabled || attrs.metadata.adsDisabled ? undefined : (this.getPlayerAds() ?? undefined);
      const title = attrs?.metadata?.title || (this.videoData?.metadata.title ?? null) || '';
      const playerLogoDisabled = attrs.metadata?.playerLogoDisabled || this.videoData?.metadata.playerLogoDisabled || false;
      const videoFile =
        this.videoData?.type === 'VIDEO'
          ? (this.videoData?.videoData?.videoPlaylistLocation ?? null)
          : (this.videoData?.streamData?.videoPlaylistLocation ?? null);
      let playerLogo = undefined;

      if (videoFile) {
        this.$emit('load-player');
      }

      if (!playerLogoDisabled) {
        playerLogo = {
          file: config.player.logoUrl,
          hide: playerLogoDisabled,
        };
      }

      const suggestionsUrl = new URL(config.api.relatedPlaylistUrl);
      const suggestionsUrlParams = new URLSearchParams({
        lang: this.$channelConfig('settings').lang,
        article: this.$store.getters['article/getActiveArticle'].id,
      });
      suggestionsUrl.search = suggestionsUrlParams.toString();

      const settings = {
        ...adSettings,
        autostart: ((autostart && attrs.autostart) ?? true) ? 'viewable' : false,
        image: this.videoPoster,
        logo: playerLogo,
        file: videoFile,
        title,
        advertising,
        tracks,
        related: {
          oncomplete: 'show',
          displayMode: this.isStream ? 'none' : 'shelf',
          onclick: 'link',
          file: suggestionsUrl.toString(),
        },
      };

      return settings;
    },
    async initPlayer() {
      if (!this.videoData) {
        return;
      }

      this.showOnlyPoster = false;
      await this.$nextTick();

      const settings = this.getSettings();

      if (!this.isLive) {
        this.showOnlyPoster = true;
        return;
      }

      // Remove previous instance from DOM if exists
      this.player?.jwplayer.remove();

      try {
        this.player = new PortalPlayer(this.containerId, settings, this.videoData, { shouldPlayerFloat: this.shouldPlayerFloat });
      } catch (e) {
        this.showOnlyPoster = true;
        console.warn(e);
      }

      this.player?.on('player-ready', () => this.player!.setLayout(this.data.attrs.type));
      this.player?.on('setup-error', () => (this.showOnlyPoster = true));
    },
    async setupPlayerWithData() {
      if (!this.data.attrs.id) {
        return;
      }

      this.videoData = this.data.attrs;

      if (!this.data.externalSource) {
        const data = await getVideo({ id: this.data.attrs.id });
        this.videoData = (data && data.video.items[0]) || null;
      }

      const unwatch = this.$store.watch(
        (state) => state.piano.isScriptInited,
        async (isScriptInited: boolean) => {
          if (isScriptInited) {
            await this.initPlayer();
            await this.$nextTick();
            unwatch();
          } else {
            const { attrs } = this.data;
            this.videoPoster = this.getVideoPoster(attrs, this.videoData);
            this.showOnlyPoster = true;
          }
        },
        {
          immediate: true,
        }
      );
    },
  },
  async mounted() {
    await this.setupPlayerWithData();

    if (this.shouldPlayerFloat) {
      document.addEventListener('scroll', this.scrollEventCallback);
    }
  },
  beforeDestroy() {
    this.player?.jwplayer.remove();
    index = 0;

    if (this.shouldPlayerFloat) {
      document.removeEventListener('scroll', this.scrollEventCallback);
    }
  },
});

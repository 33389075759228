
import Vue from 'vue';
import FragmentEmbeddedArticlesSingle from '@core/components/fragments/FragmentEmbeddedArticlesSingle.vue';
import FragmentEmbeddedArticlesDouble from '@core/components/fragments/FragmentEmbeddedArticlesDouble.vue';
import FragmentEmbeddedArticlesList from '@core/components/fragments/FragmentEmbeddedArticlesList.vue';

import { FragmentAttrsRelatedArticles } from '@core/types/fragment';
import { Article, EmbeddedArticlesElement } from '@root/modules/article/types/getArticleByID';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import { ContentFragment } from '@root/modules/article/types/article';

interface Props {
  article: Article;
  content: ContentFragment;
  domain: string;
}

interface Data {
  attributes: FragmentAttrsRelatedArticles;
  viewType: string | 'single' | 'double' | 'list';
}

interface Computed {
  embeddedArticles: EmbeddedArticlesElement[] | null;
  embeddedArticlesToUse: EmbeddedArticlesElement | undefined;
  title: string | undefined;
}

export default Vue.extend<Data, unknown, Computed, Props>({
  components: { BaseIcon, FragmentEmbeddedArticlesSingle, FragmentEmbeddedArticlesDouble, FragmentEmbeddedArticlesList },
  props: {
    article: {
      type: Object,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      attributes: this.content.attrs,
      viewType: String(this.content.attrs.type),
    };
  },
  computed: {
    embeddedArticles() {
      return this.article.embeddedArticles;
    },
    embeddedArticlesToUse() {
      if (!this.attributes.relatedArticles || !this.embeddedArticles) {
        return;
      }
      return this.embeddedArticles.find((embeddedArticle) => embeddedArticle.id === this.attributes.id);
    },
    title() {
      return this.attributes.text?.text;
    },
  },
});

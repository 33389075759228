import { Plugin } from '@nuxt/types';
import { loadScripts } from '@root/common/utils/scriptLoader';
import { getGlobalContext } from '@root/modules/ads/utils/getGlobalContext';

const gamAds: Plugin = async (ctx) => {
  const { store, app } = ctx;
  const { id, application } = app.$channelConfig('settings');

  window.googletag = window.googletag || { cmd: [] };

  const scriptSrc = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
  const lazyLoadSettings = {
    fetchMarginPercent: 50, // Start loading when the ad is 50% in the viewport
    renderMarginPercent: 30, // Render when 30% in the viewport
    mobileScaling: 2.0, // Increase the viewport size by 2x for mobile devices
  };

  try {
    await loadScripts(scriptSrc, { async: true });
  } catch (e) {
    console.error('gpt loadScript error', e);
  }

  const isScriptInitedWatcher = store.watch(
    (state) => state.piano.isScriptInited,
    async (isScriptInited: boolean) => {
      if (isScriptInited) {
        const isLoggedIn = store.state.piano.isLoggedIn;
        const { channelAccess } = store.state.piano.access;

        window.googletag.cmd.push(function () {
          // Listen to slotRenderEnded events
          window.googletag.pubads().addEventListener('slotRenderEnded', function (event) {
            store.commit('analytics/pushTrackingEvent', {
              cXensePixel: {
                eventName: 'GAM_AD',
                eventData: {
                  type: 'ad_render_state',
                  data: { adUnitId: event.slot.getAdUnitPath(), adUnitStatus: event.isEmpty ? 'failure' : 'success', adUnitSource: 'gam' },
                },
              },
            });
          });

          // Init googletag
          window.googletag.pubads().enableSingleRequest();
          window.googletag.enableServices();

          window.googletag.pubads().enableLazyLoad(lazyLoadSettings);

          // Set global targeting
          const globalContext = getGlobalContext({ channelAccess, isLoggedIn, channelId: id, isNativeApp: Boolean(application.type) });
          if (globalContext) {
            globalContext.forEach(({ key, value }) => {
              window.googletag.pubads().setTargeting(key, value);
            });
          }
        });

        isScriptInitedWatcher();
      }
    },
    {
      immediate: true,
    }
  );

  // Update ads settings when customer logs in and has access to channel or has adFree package
  store.watch(
    (state) => state.piano.access,
    async (access) => {
      const isLoggedIn = store.state.piano.isLoggedIn;
      const { channelAccess } = access;
      const globalContext = getGlobalContext({ channelAccess, isLoggedIn, channelId: id, isNativeApp: Boolean(application.type) });

      window.googletag.cmd.push(() => {
        if (globalContext) {
          globalContext.forEach(({ key, value }) => {
            window.googletag.pubads().setTargeting(key, value);
          });
          window.googletag.pubads().refresh();
        }
      });
    }
  );
};

export default gamAds;

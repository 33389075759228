import { Context } from '@nuxt/types';
import { Config } from '@root/modules/channel/types/channel';
import rootConfig from '@root/config/root.config';

function getApplication(ua: string) {
  let app = null;

  for (const application of rootConfig.applications) {
    if (ua.match(application.ua.pattern)) {
      app = application;
    }
  }

  return app;
}

function initApplicationType(req: Context['req']) {
  const ua: string | undefined | null = process.server ? req.headers['user-agent'] : navigator.userAgent;

  const rootApplication: Config['settings']['application'] = {
    type: null,
    appName: null,
    appVersion: null,
    isSearchBot: false,
    specialFeatures: {
      forceArticleRefetch: false,
      adFree: false,
      disableHeader: false,
      disableConsentModal: false,
      hideSignupAndOrders: {
        enabled: false,
        affectedApps: null,
      },
    },
  };

  if (!ua) {
    return rootApplication;
  }

  // Select application based on user agent
  const application = getApplication(ua);
  const appUARegexResult = application?.ua.pattern.exec(ua);

  rootApplication.isSearchBot = !!ua.toLowerCase().match(rootConfig.searchBotsRegex);
  rootApplication.type = application?.type || null;
  rootApplication.appName = appUARegexResult?.[application!.ua.channelCaptureGroupIndex] || null;
  rootApplication.appVersion = appUARegexResult?.[application!.ua.versionCaptureGroupIndex] || null;
  rootApplication.specialFeatures = application?.specialFeatures || rootApplication.specialFeatures;

  const { hideSignupAndOrders } = rootApplication.specialFeatures;
  const { affectedApps } = hideSignupAndOrders;

  if (hideSignupAndOrders.enabled && affectedApps?.length) {
    let hideSignupAndOrdersResult = false;

    for (const affectedApp of affectedApps) {
      const isSameVersion = appUARegexResult?.[application!.ua.versionCaptureGroupIndex] === affectedApp.version;
      const isSameChannel = appUARegexResult?.[application!.ua.channelCaptureGroupIndex] === affectedApp.channel;

      if (affectedApp.channel && affectedApp.version) {
        if (isSameVersion && isSameChannel) {
          hideSignupAndOrdersResult = true;
        }
      } else if (affectedApp.channel && isSameChannel) {
        hideSignupAndOrdersResult = true;
      } else if (affectedApp.version && isSameVersion) {
        hideSignupAndOrdersResult = true;
      }
    }

    rootApplication.specialFeatures.hideSignupAndOrders.enabled = hideSignupAndOrdersResult;
  }

  if (rootApplication.isSearchBot) {
    rootApplication.specialFeatures.disableConsentModal = true;
    rootApplication.specialFeatures.forceArticleRefetch = true;
  }

  return rootApplication;
}

export default initApplicationType;

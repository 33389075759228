import jwplayer from '@media/types/JwPlayer';
import { AudioItem } from '../../types/getAudio';
import { VideoItem } from '../../types/getVideo';

import { cXense } from '@analytics/trackers';
import { loadScript } from '../loadScript';
import PlayerAnalytics from './BaseAnalytics';

import config from '../../config/media.config';

interface cXWindow extends Window {
  [index: string]: any;
}

declare let window: cXWindow;

class cXenseTracker extends PlayerAnalytics {
  private _cX!: cXense;
  private _options = { origin: config.analytics.cXense.origin, persistedQueryId: config.analytics.cXense.persistedQueryId };

  constructor(player: jwplayer.JWPlayer, mediaData: AudioItem | VideoItem) {
    super(player, mediaData);
  }

  async initAnalytics() {
    if (!window.cX) {
      loadScript({ script: '//cdn.cxense.com/cx.js', attr: { async: 'async' } })
        .then(async () => {
          this._cX = new cXense();
          await this._cX.init({ uid: config.analytics.cXense.siteId });
          this.sendEvent('media_ready');
        })
        .catch((e) => console.error('cXense loadScript error', e));
    } else {
      this._cX = new cXense();
      await this._cX.init({ uid: config.analytics.cXense.siteId });
      this.sendEvent('media_ready');
    }
    this.setEventsListeners();

    this._player.on('float', (event: jwplayer.EventParams['float']) => {
      if (event.floating) {
        this.sendEvent('media_player_floats');
      } else {
        this.sendEvent('media_player_fixed');
      }
    });
    this._player.getContainer().addEventListener('floating-player-closed', () => {
      this.sendEvent('media_player_closed');
    });

    this._player.on('error', () => {
      this.sendEvent('media_error');
    });

    this._player.on('adError', () => {
      this.sendEvent('media_ad_error');
    });
  }

  public sendEvent(eventName: string) {
    const parameters = this.eventParameters();
    this._cX.sendEvent(eventName, parameters, this._options);
  }
}

export { cXenseTracker };

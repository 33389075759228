import { buildRoutePath } from '@root/router/utils/buildRoutePath';

// Check if ONE value matches against validator.
export function oneOf(value: string, validList: string[]) {
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true;
    }
  }
  return false;
}

export function positionClass(position: string) {
  return `float-${position}`;
}

export function getBaseDomain() {
  if (!document?.location?.hostname) {
    return false;
  }

  return `.${document.location.hostname.split('.').slice(-2).join('.')}`;
}

export function appendChild(element: HTMLElement | null, child: HTMLScriptElement) {
  if (!element || !element.appendChild) {
    return;
  }
  element.appendChild(child);
}

export interface Settings {
  script: string;
  attr: Record<string, unknown>;
  parentElement?: HTMLElement;
}

export const loadScript = (settings: Settings): Promise<boolean> => {
  return new Promise((resolve) => {
    const attr = settings.attr;
    const parentElement = settings.parentElement || document.getElementsByTagName('head')[0];

    const scriptEl = document.createElement('script');
    scriptEl.src = settings.script;
    scriptEl.type = 'text/javascript';
    if (attr) {
      Object.keys(attr).forEach((val) => {
        (scriptEl as any)[val] = attr[val];
      });
    }

    // Attach script to head
    appendChild(parentElement, scriptEl);
    // Wait for tag to load before promise is resolved
    scriptEl.addEventListener('load', () => {
      resolve(true);
    });
  });
};

// @ts-ignore
interface $nuxtWindow extends Window {
  $nuxt: {
    error: ({ statusCode, message }: { statusCode: number; message?: string }) => void;
    $channelConfig: (key: string) => Record<string, any>;
  };
}

declare let window: $nuxtWindow;
export function forceRootError({ statusCode, message }: { statusCode: number; message?: string } = { statusCode: 404 }) {
  const $nuxt = typeof window !== 'undefined' && window.$nuxt;

  if ($nuxt) {
    window.$nuxt.error({ statusCode, message });
  }
}

export function getRootConfig(key: string) {
  const $nuxt = typeof window !== 'undefined' && window.$nuxt;

  if ($nuxt) {
    return window.$nuxt.$channelConfig(key);
  }
}

export function isUndefined(value: unknown) {
  return typeof value === 'undefined';
}

export function convertArrayToObject<T>(value: unknown, defaultObject?: Record<string, unknown>) {
  if (!value) {
    return defaultObject || undefined;
  }

  return (value as { key: string; value: string }[])?.reduce(
    (map, { key, value }) => {
      return { ...map, [key]: value };
    },
    (defaultObject || {}) as T
  );
}

export function isObject(object: any) {
  if (typeof object === 'object' && !Array.isArray(object) && object !== null) {
    return true;
  }

  return false;
}

interface Channel {
  language: string;
  domain: string;
}

export const buildArticleRoute = (articleId: number, slug: string, channel: Channel, domain: string) => {
  const routeOptions = {
    pathOptions: {
      type: 'article',
      id: articleId,
      slug,
      channel: {
        language: channel.language,
        domain: channel.domain,
      },
    },
    domain,
  };

  return buildRoutePath(routeOptions);
};

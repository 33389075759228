import { Plugin } from '@nuxt/types';

const ArticleViewStatisticsPlugin: Plugin = async (ctx) => {
  const { app, store } = ctx;
  const analytics = app.$channelConfig('analytics');

  if (!analytics?.articleStatistics?.url) {
    return;
  }

  const { articleStatistics } = analytics;

  // Watch activeArticlePath store state to send article page view
  store.watch(
    (state) => state.article.activeArticlePath,
    async (activeArticlePath) => {
      if (activeArticlePath && store.state.article.activeArticle) {
        new Image().src = `${articleStatistics.url}/${store.state.article.activeArticle.id}`;
      }
    },
    {
      immediate: true,
    }
  );

  store.watch(
    (state) => state.analytics.arrayTracker.articleViewStatistics,
    async (eventsArray) => {
      if (!eventsArray.length) {
        return;
      }

      eventsArray.forEach(({ eventData }: { eventData: { type: string; data: { abTestGroup?: string; articleId?: number } } }) => {
        const { type, data } = eventData;

        if (!(type || data?.abTestGroup || data?.articleId)) {
          return;
        }

        const { abTestGroup, articleId } = data;

        if (type === 'impression') {
          console.log('impression', abTestGroup, articleId);
          new Image().src = `${articleStatistics.url}/i/${abTestGroup}`;
        }

        if (type === 'click') {
          console.log('click', abTestGroup, articleId);
          new Image().src = `${articleStatistics.url}/c/${abTestGroup}`;
        }
      });

      // Remove only the processed items
      eventsArray.splice(0, eventsArray.length);
    }
  );
};

export default ArticleViewStatisticsPlugin;


import { FragmentAttrsRelatedArticles } from '@core/types/fragment';

import { EmbeddedArticlesElement } from '@root/modules/article/types/getArticleByID';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { formatCustomDate } from '@core/exports';
import { buildArticleRoute } from '@core/utils/helpers';
import FragmentEmbeddedArticle from './FragmentEmbeddedArticle';

interface Props {
  attributes: FragmentAttrsRelatedArticles;
  domain: string;
  embeddedArticles: EmbeddedArticlesElement;
}

interface Methods {
  buildArticleRoute: typeof buildArticleRoute;
  formatCustomDate: typeof formatCustomDate;
}

export default FragmentEmbeddedArticle.extend<unknown, Methods, unknown, Props>({
  components: { LinkHandler },
  props: {
    attributes: {
      type: Object,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
    embeddedArticles: {
      type: Object,
      required: true,
    },
  },
  methods: { buildArticleRoute, formatCustomDate },
});

export default {
  nextUp: 'Следующее видео',
  errors: {
    cantLoadPlayer: 'Возникла проблема с показом плеера, обновите страницу или попробуйте позже.',
    liveStreamDown: 'Прямая трансляция сейчас недоступна или завершилась',
    cantPlayInBrowser: 'Ваш браузер не поддерживает воспроизведение этого видео. <br>Попробуйте другой браузер.',
  },
  sharing: {
    heading: 'Поделиться',
  },
  advertising: {
    cuetext: '',
    displayHeading: 'Видео начнется после рекламы',
    loadingAd: 'Загружается реклама',
    skipmessage: 'Пропустить рекламу через xx',
    skiptext: 'Пропустить',
    admessage: 'Реклама закончится через xx секунд.',
    podmessage: 'Реклама __AD_POD_CURRENT__/__AD_POD_LENGTH__',
  },
  related: {
    heading: 'Популярные видео',
  },
};

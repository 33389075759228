
import Vue from 'vue';
import fragmentImporter from '@core/utils/fragmentImporter';
import { FragmentContent, FragmentSettings } from '@core/types/fragment';

import fragmentConfig from '@core/config/fragment.config';
import { Article } from '@root/modules/article/types/getArticleByID';

interface Data {
  contentData: Record<string, any>;
}

interface Methods {
  getEmbedFragmentName: (attributes: FragmentContent['attrs']) => string | null;
  getFragment: (content: FragmentContent) => string;
}

interface Props {
  article: Article;
  content: FragmentContent;
  settings: FragmentSettings;
  domain: string;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    ...fragmentImporter,
  },
  props: {
    article: {
      type: Object,
      required: false,
      default: null,
    },
    content: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: false,
      default: null,
    },
    domain: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    getEmbedFragmentName(attrs) {
      if (!attrs || !attrs?.type) {
        return null;
      }

      const embed = fragmentConfig.type.embed;
      const type = attrs.type && attrs.type.toLowerCase();

      let fragmentName = null;

      if (type) {
        fragmentName = embed[type] || embed['default'];
      }

      return fragmentName;
    },
    // Get correct fragment for render
    // If fragmentType is missing then import FragmentHtml as fallback
    getFragment(content) {
      const { attrs } = content;
      const type = content.type && content.type.toLowerCase();

      let fragmentName: string | null = fragmentConfig.type[type] || null;

      if (type === 'media') {
        const mediaType = attrs?.type?.toLowerCase() || '';
        fragmentName = fragmentConfig.type[type][mediaType];
      }

      if (type === 'embed') {
        fragmentName = this.getEmbedFragmentName(attrs);
      }

      if (type === 'relatedarticleselement') {
        fragmentName = fragmentConfig.type['relatedarticleselement'];
        return fragmentConfig.type['embeddedArticles'];
      }

      if (fragmentName && this?.$options?.components && !(fragmentName in this.$options.components)) {
        return fragmentConfig.type['html'];
      }

      if (this.$options.components![`${fragmentName}`]) {
        return fragmentName;
      }
      return fragmentConfig.type['html'];
    },
  },
});

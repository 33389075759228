import { Data as Headlines } from '@root/modules/headlines/types/headlines';
import { Headline } from '@headlines/types/headline';
import { MetaImageAttributes } from '@root/common/types/fragment';

export const getAbTestHeadlineId = (headline: Headlines | Headline, abTestGroup: string | undefined): string | undefined => {
  const titleEntries = headline?.content?.activeExperiment?.entries || [];

  switch (abTestGroup) {
    case 'A':
      return titleEntries[0]?.id;
    case 'B':
      return titleEntries[1]?.id;
  }
};

export const getAbTestHeadlineTitle = (headline: Headline, abTestGroup: 'A' | 'B' | undefined): string | undefined | null => {
  const titleEntries = headline?.content?.activeExperiment?.entries || [];

  switch (abTestGroup) {
    case 'A':
      return titleEntries[0]?.title?.text;
    case 'B':
      return titleEntries[1]?.title?.text;
  }
};

export const getAbTestHeadlineImage = (
  headline: Headline,
  abTestGroup: 'A' | 'B' | undefined
): { id: string | undefined; cropperData: MetaImageAttributes['cropperData'] | undefined } => {
  const imageEntries = headline?.content?.activeExperiment?.entries || [];

  switch (abTestGroup) {
    case 'A':
      return {
        id: imageEntries[0]?.metaImage?.id,
        cropperData: imageEntries[0]?.metaImage?.attributes?.cropperData as MetaImageAttributes['cropperData'],
      };
    case 'B':
      return {
        id: imageEntries[1]?.metaImage?.id,
        cropperData: imageEntries[1]?.metaImage?.attributes?.cropperData as MetaImageAttributes['cropperData'],
      };
    default:
      return {
        id: undefined,
        cropperData: undefined,
      };
  }
};


import Vue from 'vue';
import BigPictureWrapper from '@gallery/components/BigPictureWrapper.vue';
import { GalleryMode, GetGalleries, ImageItem } from '@gallery/types/Gallery';
import ThumbWrapper from '@gallery/components/thumb/ThumbWrapper.vue';
import LightboxInfo from './LightboxInfo.vue';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import axios from 'axios';
import { getPictureCredit } from '@gallery/utils/getPictureCredit';
import getLightboxFullImgAdUrl from '@gallery/utils/getLightboxFullImgAdUrl';
import lightboxConfig from '@gallery/config/lightbox.config';

interface AdObject {
  banner: string;
  height: number;
  width: number;
}

interface Data {
  activePictureIndex: number;
  changedPicturesCount: number;
  nextAd: {
    banner: string;
    height: number;
    width: number;
  } | null;
  adTimer: ReturnType<typeof setTimeout> | undefined;
  showThumbWrapper: boolean;
  showMobileDescription: boolean;
  navigationDisabled: boolean;
  showScrollerAd: boolean;
  showScrollerAdCloseBtn: boolean;
  activePictureCredits: string;
  shownAdsCount: number;
  isAdActive: boolean;
}

interface Methods {
  handleImageChange: (index: number) => Promise<void>;
  nativeShare: () => void;
  addPictureRelatedHash: () => void;
  keyboardNavigation: (e: KeyboardEvent) => void;
  toggleThumbWrapper: () => void;
  setMobileDescription: (show: boolean) => void;
  hideScrollerAd: () => void;
  displayScrollerAdBtn: () => void;
  fetchMoreImages: () => void;
  clearAdTimer: () => void;
  fetchAd: () => Promise<AdObject | null>;
  activateAdTimer: () => void;
  resetAdConditions: () => void;
  prepareAd: () => Promise<void>;
  showAd: () => void;
  changeImage: (index: number) => void;
  handleAdConditions: () => Promise<boolean>;
}

interface Computed {
  activePicture: ImageItem;
  disableFullImageAd: boolean;
  adsAttributes: {
    mkw: string[];
    mkv: Record<string, string>;
  };
  hasChannelSubscription: boolean;
  preLoadAdAfterNthImage: number;
  hasSubscriberGottenMaxAds: boolean;
  adjustedShowAdAfterNthPicture: number;
  canFetchAd: boolean;
  canShowAd: boolean;
}

interface Props {
  gallery: GetGalleries['data']['gallery']['items'][0];
  initialIndex: number;
  mode: GalleryMode;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    BigPictureWrapper,
    ThumbWrapper,
    LightboxInfo,
    AdFormBanner,
    BaseIcon,
  },
  props: {
    gallery: {
      type: Object,
      required: true,
    },
    initialIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    mode: {
      type: String as () => GalleryMode,
      required: false,
      default: 'gallery',
    },
  },
  data() {
    return {
      activePictureIndex: this.initialIndex,
      changedPicturesCount: 0,
      nextAd: null,
      adTimer: undefined,
      showThumbWrapper: false,
      showMobileDescription: false,
      navigationDisabled: false,
      showScrollerAd: true,
      showScrollerAdCloseBtn: false,
      activePictureCredits: '',
      shownAdsCount: 0,
      isAdActive: false,
    };
  },
  computed: {
    hasSubscriberGottenMaxAds() {
      return this.hasChannelSubscription && this.shownAdsCount >= lightboxConfig.subscriberMaxShownAds;
    },
    adjustedShowAdAfterNthPicture() {
      return this.shownAdsCount < 1 ? lightboxConfig.showFirstAdAfterNthPicture : lightboxConfig.showAdAfterNthPicture;
    },
    preLoadAdAfterNthImage() {
      const offsetNumber = 2;
      const preFetchIndexFallback = 2;
      const getPreFetchIndex = Math.max(this.adjustedShowAdAfterNthPicture - offsetNumber, preFetchIndexFallback);
      return getPreFetchIndex;
    },
    canFetchAd() {
      const imgChangeCountMatchesPreLoadIndex = this.changedPicturesCount >= this.preLoadAdAfterNthImage;
      return imgChangeCountMatchesPreLoadIndex && !this.disableFullImageAd;
    },
    canShowAd() {
      const imgChangeCountMatchesAdIndex = this.changedPicturesCount >= this.adjustedShowAdAfterNthPicture;
      return imgChangeCountMatchesAdIndex && !this.disableFullImageAd && Boolean(this.nextAd);
    },
    hasChannelSubscription() {
      return this.$store.state.piano.access.channelAccess;
    },
    activePicture() {
      return this.gallery.images.items[this.activePictureIndex];
    },
    disableFullImageAd() {
      const subscriberHasSeenAd = this.hasSubscriberGottenMaxAds;
      const matchesDisabledAdsList = this.$store.getters['adForm/getDisabledAdsList']?.includes('content');
      const isModeSingle = this.mode === 'single';

      return subscriberHasSeenAd || matchesDisabledAdsList || isModeSingle;
    },
    adsAttributes() {
      const keywords: string[] = this.$store.getters['adForm/getAdsKeywords'];
      const keyValues: Record<string, string> = this.$store.getters['adForm/getAdsKeyValues'];

      return {
        mkw: keywords,
        mkv: keyValues,
      };
    },
  },
  watch: {
    activePicture: {
      handler(newVal) {
        this.activePictureCredits = getPictureCredit(newVal?.metadata);
      },
      immediate: true,
    },
  },
  methods: {
    async handleImageChange(index) {
      if (this.navigationDisabled) {
        return;
      }

      const canChangeImg = await this.handleAdConditions();
      if (!canChangeImg) {
        return;
      }

      if (this.changedPicturesCount >= this.adjustedShowAdAfterNthPicture) {
        this.resetAdConditions();
      }

      this.changeImage(index);
    },
    async handleAdConditions() {
      if (this.canFetchAd) {
        await this.prepareAd();
      }

      if (!this.adTimer && this.isAdActive) {
        this.resetAdConditions();
      }

      if (this.canShowAd) {
        this.showAd();
        return false;
      }

      return true;
    },
    changeImage(index) {
      if (index < this.gallery.images.items.length && index >= 0) {
        this.activePictureIndex = index;
        this.changedPicturesCount++;

        if (index > this.gallery.images.items.length - 5) {
          this.fetchMoreImages();
        }
      }

      this.addPictureRelatedHash();
    },
    async prepareAd() {
      const adElement = await this.fetchAd();
      if (adElement) {
        this.nextAd = adElement;
      }
    },
    showAd() {
      this.isAdActive = true;
      this.activateAdTimer();
      this.shownAdsCount++;
    },
    resetAdConditions() {
      this.changedPicturesCount = 0;
      this.nextAd = null;
      this.isAdActive = false;
    },
    activateAdTimer() {
      this.navigationDisabled = true;
      this.adTimer = setTimeout(() => {
        this.navigationDisabled = false;
        this.clearAdTimer();
      }, 1500);
    },
    clearAdTimer() {
      if (this.adTimer) {
        clearTimeout(this.adTimer);
      }

      this.adTimer = undefined;
    },
    addPictureRelatedHash() {
      const hash = new URLSearchParams();

      if (this.mode === 'gallery') {
        hash.append('gallery-id', this.gallery.id);
        hash.append('image-id', this.activePicture.id);
      }

      if (this.mode === 'single') {
        hash.append('article-image-id', this.activePicture.id);
      }

      history.replaceState(null, '', `#${hash.toString()}`);
    },
    nativeShare() {
      window.navigator.share({
        text: this.activePicture.metadata.title || '',
        url: window.location.href,
      });
    },
    keyboardNavigation(e) {
      switch (e.key) {
        case 'ArrowLeft':
          this.handleImageChange(this.activePictureIndex - 1);
          break;
        case 'ArrowRight':
          this.handleImageChange(this.activePictureIndex + 1);
          break;
        case 'Escape':
          this.$emit('close-lightbox');
          break;
      }
    },
    async fetchAd() {
      const adUrlParams = {
        adId: this.$store.getters['adForm/getPPID'],
        adsAttributes: this.adsAttributes,
      };

      const adUrl = getLightboxFullImgAdUrl(adUrlParams).href;

      try {
        const { data } = await axios.get(adUrl);
        const adObject = JSON.parse(data.substring(5, data.length - 2));
        if (adObject?.banner) {
          const isWidthAndHeightValid = Number.isInteger(adObject.width) && Number.isInteger(adObject.height);

          return {
            banner: adObject.banner,
            height: isWidthAndHeightValid ? adObject.height : 1080,
            width: isWidthAndHeightValid ? adObject.width : 1920,
          };
        }
      } catch {
        console.error('Gallery: Could not query for ad');
      }

      return null;
    },
    toggleThumbWrapper() {
      this.showThumbWrapper = !this.showThumbWrapper;
    },
    setMobileDescription(show) {
      this.showMobileDescription = show;
    },
    displayScrollerAdBtn() {
      this.showScrollerAdCloseBtn = true;
    },
    hideScrollerAd() {
      this.showScrollerAd = false;
      this.showScrollerAdCloseBtn = false;
    },
    fetchMoreImages() {
      this.$emit('fetch-more-images');
    },
  },
  mounted() {
    this.addPictureRelatedHash();
    window.addEventListener('toggleScrollerAdBtn', this.displayScrollerAdBtn);
    window.addEventListener('keydown', this.keyboardNavigation);
  },
  beforeDestroy() {
    window.removeEventListener('toggleScrollerAdBtn', this.displayScrollerAdBtn);
    window.removeEventListener('keydown', this.keyboardNavigation);
  },
});

import { TrackingData } from '@root/common/types/fragment';

export default class TrackingInfoDecoder {
  public validTrackingData(trackingData: unknown): boolean {
    if (typeof trackingData !== 'object' || trackingData === null) {
      return false;
    }

    const isRequiredFieldsValid =
      'pageType' in trackingData &&
      'siteName' in trackingData &&
      'blockType' in trackingData &&
      'blockIndex' in trackingData &&
      'sectionIndex' in trackingData &&
      typeof trackingData.pageType === 'string' &&
      typeof trackingData.siteName === 'string' &&
      typeof trackingData.blockType === 'string' &&
      typeof trackingData.blockIndex === 'number' &&
      typeof trackingData.sectionIndex === 'number';

    const hasBlockId = 'blockId' in trackingData;
    const hasAbTestGroup = 'abTestGroup' in trackingData;

    if (hasBlockId) {
      return isRequiredFieldsValid && typeof trackingData.blockId === 'string';
    }

    if (hasAbTestGroup) {
      return isRequiredFieldsValid && typeof trackingData.abTestGroup === 'string';
    }

    return isRequiredFieldsValid;
  }

  public encode(trackingData: TrackingData): string | undefined {
    if (!this.validTrackingData(trackingData)) {
      return undefined;
    }
    try {
      const jsonString = JSON.stringify(trackingData);
      const base64String = btoa(jsonString);
      const reversedString = base64String.split('').reverse().join('');
      const encodedString = encodeURIComponent(reversedString);
      return encodedString;
    } catch (error) {
      return undefined;
    }
  }

  public decode(input: string): TrackingData | undefined {
    try {
      const reversedString = decodeURIComponent(input);
      const base64String = reversedString.split('').reverse().join('');
      const jsonString = atob(base64String);
      const trackingData = JSON.parse(jsonString);

      if (this.validTrackingData(trackingData)) {
        return trackingData;
      } else {
        throw new Error('Decoded tracking data does not match');
      }
    } catch (error) {
      return undefined;
    }
  }
}

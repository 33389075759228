import TrackingInfoDecoder from '@root/common/utils/TrackingInfoDecoder';
import { QueryParameter } from '@root/common/types/queryParameter';

import { Route } from 'vue-router';
import { Store } from 'vuex';

const getAbTestGroup = (fullPath: string): string | undefined => {
  if (typeof window === 'undefined') {
    return;
  }

  const url = new URL(fullPath, window.location.origin);
  const trackingParameter = url.searchParams.get(QueryParameter.TrackingParameter);

  if (trackingParameter) {
    const trackingInfoDecoder = new TrackingInfoDecoder();
    const trackingData = trackingInfoDecoder.decode(trackingParameter);

    return trackingData?.abTestGroup;
  }
};

export const useAbTestEvents = ($route: Route, $store: Store<unknown>) => {
  // Read abTestGroup from route in article page
  const abTestGroupFromRoute = getAbTestGroup($route.fullPath);

  const sendAbTestArticleEvent = ({ type, abTestGroup, articleId }: { type: 'impression' | 'click'; abTestGroup?: string; articleId: number }): void => {
    const abGroup = abTestGroup || abTestGroupFromRoute;

    if (!abGroup) {
      return;
    }

    $store.commit('analytics/pushTrackingEvent', {
      articleViewStatistics: {
        eventName: 'AB_TEST',
        eventData: { type, data: { abTestGroup: abGroup, articleId } },
      },
    });
  };

  return { sendAbTestArticleEvent };
};

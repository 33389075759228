// NB! When adding new specialFeatures it's important to make sure that varnish cache
// between different applications wont cause vue hydration errors
import { Config } from '@root/modules/channel/types/channel';

type ConfigApplication = Pick<Config['settings']['application'], 'type' | 'specialFeatures'>;
interface Application extends ConfigApplication {
  ua: {
    pattern: RegExp;
    channelCaptureGroupIndex: number;
    versionCaptureGroupIndex: number;
  };
}

const getMainPortalUrl = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.DEV_PATH as string;
  }

  if ((process.env.CHANNEL_CONFIG_ENV === 'review' || process.env.CHANNEL_CONFIG_ENV === 'test') && process.env.GITLAB_ENVIRONMENT_URL) {
    const url = new URL(process.env.GITLAB_ENVIRONMENT_URL as string);
    return url.origin;
  }

  return 'https://www.delfi.ee';
};

const rootConfig: RootConfig = {
  mainPortalUrl: getMainPortalUrl(),
  social_share: {
    facebook: 'https://www.facebook.com/sharer/sharer.php',
    twitter: 'https://twitter.com/share',
  },
  comment_frontend_url: 'https://comment-fe.delfi.ee/loader.js',
  applications: [
    {
      type: 'NativeAppIOS',
      ua: {
        pattern: /(DelfiEE|DelfiLT|DelfiLV)([a-zA-Z]+)\/(\d+).*(ipad|iphone|ipod)/gi,
        channelCaptureGroupIndex: 2,
        versionCaptureGroupIndex: 3,
      },
      specialFeatures: {
        forceArticleRefetch: false,
        adFree: false,
        disableHeader: false,
        disableConsentModal: false,
        hideSignupAndOrders: {
          enabled: false,
          // Correct channel values: 'www' | 'rudelfi' | 'sport' | 'ekspress' | 'epl' | 'maaleht'
          affectedApps: [],
        },
      },
    },
    {
      type: 'NativeAppAndroid',
      ua: {
        pattern: /(DelfiEE|DelfiLT|DelfiLV)([a-zA-Z]+)\/(\d+).*(android)/gi,
        channelCaptureGroupIndex: 2,
        versionCaptureGroupIndex: 3,
      },
      specialFeatures: {
        forceArticleRefetch: false,
        adFree: false,
        disableHeader: false,
        disableConsentModal: false,
        hideSignupAndOrders: {
          enabled: false,
          // Correct channel values: 'www' | 'rudelfi' | 'sport' | 'ekspress' | 'epl' | 'maaleht'
          affectedApps: null,
        },
      },
    },
    {
      type: 'NewNativeAppIOS',
      ua: {
        pattern: /([A-Z-]+) ([0-9]+) iphone .*/gi,
        channelCaptureGroupIndex: 1,
        versionCaptureGroupIndex: 2,
      },
      specialFeatures: {
        forceArticleRefetch: false,
        adFree: false,
        disableHeader: false,
        disableConsentModal: false,
        hideSignupAndOrders: {
          enabled: false,
          // Correct channel values: 'DELFI-EE' | 'MAALEHT-EE' | 'DELFI-EE-RUS'
          affectedApps: null,
        },
      },
    },
    {
      type: 'NewNativeAppAndroid',
      ua: {
        pattern: /([A-Z-]+) ([0-9]+) android .*/gi,
        channelCaptureGroupIndex: 1,
        versionCaptureGroupIndex: 2,
      },
      specialFeatures: {
        forceArticleRefetch: false,
        adFree: false,
        disableHeader: false,
        disableConsentModal: false,
        hideSignupAndOrders: {
          enabled: false,
          // Correct channel values: 'DELFI-EE' | 'MAALEHT-EE' | 'DELFI-EE-RUS'
          affectedApps: null,
        },
      },
    },
  ],
  searchBotsRegex: 'google|rahvusraamatukogu|cxensebot|bingbot|bingpreview|applebot|search|seokicks|yandex',
  adBlockVideosIds: {
    default: {
      adBlock: 'f9b4bf60-a0c0-11ec-a2af-512016e225b7',
      adBlockPlus: 'f9c1dec0-a0c0-11ec-afdb-a9205bbd5cc5',
    },
    ru: {
      adBlock: 'f9b4e670-a0c0-11ec-a2af-512016e225b7',
      adBlockPlus: 'f9c0f460-a0c0-11ec-afdb-a9205bbd5cc5',
    },
  },
};

export interface RootConfig {
  mainPortalUrl: string;
  social_share: {
    facebook: string;
    twitter: string;
  };
  comment_frontend_url: string;
  adBlockVideosIds: Record<
    string,
    {
      adBlock: string;
      adBlockPlus: string;
    }
  >;
  searchBotsRegex: string;
  applications: Application[];
}

export default rootConfig;
